import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Accordion, Button, Field, Groupper, Header, Input, Message, Image } from 'react-frontier';
import { Countdown } from '@arema/components';
import { OrderData } from '../CheckoutClasses';
import { addCommas, formatCreditCard, formatExpiration, formatSeatNumber, unix, bindFormChange } from '@arema/components/Util';
import { Location, SetLoading } from '@arema/components/Classes';
import { usePayfront, useTitle } from '@arema/components/Hooks';
import { Icon } from 'semantic-ui-react';
import { CDN_URL, PAGE_PUBLIC, PAYFRONT_CLIENT, PAYFRONT_KEY, PLACEHOLDER_IMG } from '../CheckoutConfig';
import API from '../CheckoutAPI';
import CheckoutForm from './CheckoutForm';
import CheckoutEnd from './CheckoutEnd';
import Validator from '@arema/components/Validator';
import moment from 'moment';

import '../styles/checkout_payment.scss';
import '../styles/checkout_end.scss';

interface PaymentScreenProps{
	payment?: boolean,
}

var Payment = (props: PaymentScreenProps)=>{
	var params = useParams();
	var payfront = usePayfront(PAYFRONT_CLIENT, PAYFRONT_KEY)
	var { setTitle } = useTitle();
	var [data, setData] = useState<OrderData>(null);
	var [sending, setSending] = useState<boolean>(false);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [cardPrompts, setCardPrompts] = useState<string[]>(null);
	var [locationPrompts, setLocationPrompts] = useState<string[]>(null);
	var [checkoutPrompts, setCheckoutPrompts] = useState<string>(null);
	var [expired, setExpired] = useState<boolean>(false);
	var [paymentForce, setPaymentForce] = useState<boolean>(props.payment);
	var [location, setLocation] = useState<Location>({
		street: '',
		city: '',
		state: '',
		zipcode: '',
		country: 'México',
		neighborhood: '',
		exterior_number: '',
		interior_number: '',
	});
	var [cardForm, setCardForm] = useState({
		card_name: '',
		card_number: '',
		card_expiration: '',
		card_code: '',
		postal_code: '',
	});
	var [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		payment_method: null,
		coupon: '',
		zipcode: '',
	});

	useEffect(()=>{
		if(!data && params && params.token){
			API.getOrder(params.token).then(res=>{
				if(res.error){
					if(res.code=='C-EXPORD') return setExpired(true);
					else return setLoadError(res.message);
				}
				setData(res.data);
				setFormData({
					first_name: res.data.order.first_name,
					last_name: res.data.order.last_name,
					email: res.data.order.email,
					payment_method: null,
					coupon: '',
					zipcode: '',
				});
				if(paymentForce && res.data && res.data.tickets){
					if(!res.data.tickets.find(a=>!a.paid)){
						setPaymentForce(false);
					}
				}
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando la información de la orden.');
			});
		}
		window.document.title = 'Pago - AREMA Ticket';
	}, [params]);

	useEffect(()=>{
		if(!data || !data.order) return;
		if(!paymentForce && (data.order.finished || data.order.paid)){
			setTitle(`Compra ${data.order.order_hash}`);
		}else{
			setTitle(`Pago ${data.order.order_hash}`);
		}
	}, [paymentForce, data]);

	if(expired){
		return <div>
			<Header text='Orden expirada' subtext='El tiempo de compra de esta orden se ha expirado.' iconName='face-frown' />
			<Button text='Regresar' style={{ display: 'block', margin: 'auto', width: 200 }} color='black' href={PAGE_PUBLIC} />
		</div>
	}
	
	if(!data || loadError){
		return <Header text={loadError ? 'Error' : 'Cargando orden...'} subtext={loadError || null} iconName={loadError ? 'face-frown-open' : null} loading={!loadError} />
	}

	if(!paymentForce && data && data.order && (data.order.finished || data.order.paid)){
		return <CheckoutEnd data={data} onForcePayment={()=>setPaymentForce(true)} />
	}

	var getPaymentCost = ()=>{
		var payment_cost = 0;
		var order_total = data.order.delivery_cost;
		for(var i of data.tickets){
			if(i.paid) continue;
			order_total += i.ticket_cost + i.commission;
		}

		if(formData.payment_method){
			var pm = data.payment_methods.find(a=>a.payment_method==formData.payment_method);
			if(pm){
				if(pm.payment_method<20){ // IS CARD
					payment_cost = (data.order.card_commission || 0)*order_total;
				}else{
					payment_cost = pm.commission_amount+(order_total*pm.commission_percent);
				}
			}
		}

		return payment_cost;
	}

	var formFinish = ()=>{
		var o = {...data};
		o.order.form_answered = true;
		setData(o);
	}

	var submitPayment = async (setLoading: SetLoading)=>{
		setCheckoutPrompts(null);
		var { valid, prompts } = Validator(formData, {
			first_name: [
				{ rule: 'empty', prompt: 'Favor de ingresar el nombre del comprador.' },
				{ rule: 'minLength', skipEmpty: true, params: [3], prompt: 'El nombre del comprador debe de ser de mas de 3 caracteres.' },
				{ rule: 'maxLength', params: [32], prompt: 'El nombre del comprador debe de ser de menos de 32 caracteres.' },
			],
			last_name: [
				{ rule: 'empty', prompt: 'Favor de ingresar el apellido del comprador.' },
				{ rule: 'minLength', skipEmpty: true, params: [3], prompt: 'El apellido del comprador debe de ser de mas de 3 caracteres.' },
				{ rule: 'maxLength', params: [32], prompt: 'El apellido del comprador debe de ser de menos de 32 caracteres.' },
			],
			email: [{
				rule: 'email', prompt: 'El correo electrónico no es válido.'
			}],
			payment_method: [{
				rule: 'number', prompt: 'Favor de seleccionar un método de pago.',
			}],
			zipcode: [
				{ rule: 'minLength', params: [5], prompt: 'El código postal no es válido', if: !data.order.needs_location },
				{ rule: 'number', prompt: 'El código postal no es válido', if: !data.order.needs_location }
			]
		});
		var method = formData.payment_method ? data.payment_methods.find(a=>a.payment_method==formData.payment_method) : null;
		if(!method && formData.payment_method) prompts.push('El método de pago no es válido');
		setErrorPrompts(prompts);
		if(!valid) return;

		if(data.order.needs_location){
			var { valid: locValid, prompts: locPrompts } = Validator(location, {
				street: [{ rule: 'minLength', params: [3], label: 'Calle' }],
				exterior_number: [{ rule: 'empty', prompt: 'El número exterior no es válido.', }],
				interior_number: [{ rule: 'empty', skipEmpty: true, prompt: 'El número interior no es válido.' }],
				neighborhood: [{ rule: 'minLength', params: [3], label: 'Colonia' }],
				city: [{ rule: 'minLength', params: [3], label: 'Ciudad' }],
				zipcode: [
					{ rule: 'minLength', params: [5], prompt: 'El código postal no es válido' },
					{ rule: 'number', prompt: 'El código postal no es válido' },
				],
			});
			setLocationPrompts(locPrompts)
			if(!locValid) return;
		}

		var card_token = null;
		if(formData.payment_method && formData.payment_method<20){ // IS CARD
			var { valid, prompts } = Validator(cardForm, {
				card_name: [{ rule: 'minLength', params: [4], prompt: 'El nombre de tarjetahabiente no es válido.' }],
				card_number: [{ rule: 'minLength', params: [18], prompt: 'El nombre de tarjetahabiente no es válido.' }],
				card_code: [{ rule: 'minLength', params: [3], prompt: 'El código de seguridad no es válido.' }],
				card_expiration: [{ rule: /[0-1][0-9]\/[0-9]{2}/gi, prompt: 'La fecha de expiración no es válida.' }],
				postal_code: [{ rule: 'minLength', params: [4], prompt: 'El código postal no es válido.' }],
			});
			setCardPrompts(prompts);
			if(!valid) return;
			try{
				var [month, year] = cardForm.card_expiration.split('/');
				var total_final = 0;
				for(var i of data.tickets){
					if(i.paid) continue;
					total_final += i.ticket_cost+i.commission;
				}
				total_final += data.order.delivery_cost;
				var payment_cost = getPaymentCost();
				total_final += payment_cost;
				
				setLoading(true);
				var payfront_res = await payfront.createToken({
					card_name: cardForm.card_name,
					card_number: cardForm.card_number.replace(/[^0-9]/gi, ''),
					card_cvv: cardForm.card_code,
					expiration_month: parseInt(month),
					expiration_year: parseInt(year),
					amount: total_final,
					postal_code: cardForm.postal_code,
				});
				if(payfront_res.error){
					setLoading(false);
					setCheckoutPrompts(payfront_res.message);
					return;
				}
				card_token = payfront_res.data.token;
				// return;
			}catch(e){
				setLoading(false);
				setCheckoutPrompts('Hubo un error inesperado enviando la información de la tarjeta, no se realizó ningún cargo. (LCL-CKPF-1)');
				return;
			}
		}

		setLoading(true);
		try{
			var res = await API.completeOrder(params.token, {
				first_name: formData.first_name,
				last_name: formData.last_name,
				email: formData.email,
				zipcode: data.order.needs_location ? location.zipcode : formData.zipcode,
				payment_method: formData.payment_method,
				card_token,
				location: data.order.needs_location ? location : null,
			});
			if(res.error) return setCheckoutPrompts(res.message);
			if(res.data.finished || res.data.paid){
				var dt = {...data};
				dt.order = {
					...dt.order,
					...formData
				}
				if(res.data.date_expiration){
					dt.order.date_expiration = res.data.date_expiration;
				}
				dt.order.reference = res.data.reference;
				dt.order.payment_cost = res.data.payment_cost || getPaymentCost();
				dt.order.tickets_url = res.data.tickets_url;
				dt.order.finished = true;
				dt.order.paid = !!res.data.paid;
				setData(dt);
			}else{
				if(res.data.redirect){
					window.location.href = res.data.redirect;
				}else{
					return setCheckoutPrompts('Hubo un error inesperado enviando la información (LCL-CKF-2)');
				}
			}
		}catch(e){
			return setCheckoutPrompts('Hubo un error inesperado enviando la información (LCL-CKF-1)');
		}finally{
			setLoading(false);
		}
	}

	var orderExpired = ()=>{
		if(!data.order.date_expiration) return;
		setExpired((data.order.date_expiration-2)<unix());
	}

	var has_form = data.order && !data.order.form_answered && data.tickets.find(a=>a.questions && a.questions.length>0);
	var onFormChange = bindFormChange(formData, setFormData);
	var onCardFormChange = bindFormChange(cardForm, setCardForm);
	var onLocationFormChange = bindFormChange(location, setLocation);

	var tickets_total = 0, commission_total = 0;
	for(var i of data.tickets){
		if(i.paid) continue;
		tickets_total += i.ticket_cost;
		commission_total += i.commission;
	}
	var payment_cost = getPaymentCost();

	var CommissionAmount = (props: { percent: number, amount: number })=>{
		var amount = props.amount+((tickets_total+commission_total+(data.order.paid ? 0 : data.order.delivery_cost))*props.percent);
		if(amount==0){
			return null;
		}

		return <div style={{ fontWeight: 'bold' }}>
			${addCommas(amount)}
		</div>
	}

	return <div>
		{data.order.date_expiration ? (
			<div className="fr countdown message">
				Tiempo de compra
				<Countdown className='header' unix={data.order.date_expiration} onFinish={orderExpired} />
			</div>
		) : null}
		{has_form ? (
			<CheckoutForm token={params.token} order={data} onFormFinish={formFinish} />
		) : (
			<div className='ar checkout'>
				<div className='column'>
					<Groupper title='Comprador'>
						<Field amount={2}>
							<Input label='Nombre' value={formData.first_name} onChange={onFormChange('first_name')} readonly={sending || data.order.paid} />
							<Input label='Apellido' value={formData.last_name} onChange={onFormChange('last_name')} readonly={sending || data.order.paid} />
						</Field>
						<Input label='Correo electrónico' value={formData.email} onChange={onFormChange('email')} readonly={sending || data.order.paid} />
						{!data.order.needs_location && (
							<Input label='Código postal' value={formData.zipcode} onChange={onFormChange('zipcode')} readonly={sending || data.order.paid} />
						)}
						{data.order.paid ? (
							<Message type="info" text='No se pueden cambiar los datos del comprador ya que la orden fue pagada anteriormente.' />
						) : null}
						<Message list={errorPrompts} type='error' style={{ marginBottom: 0, marginTop: 10 }} />
					</Groupper>
					<Groupper title='Forma de pago' style={{ marginTop: 10 }} fitted>
						<Accordion divided selectable toggle onSelected={onFormChange('payment_method')}>
							{data.payment_methods.map(a=>(
								a.payment_method<20 ? ( // IS CARD
									<Accordion.Item key={`pm-ck-${a.payment_method}`} value={a.payment_method} title={a.method_name} rightTitle={(
										<CommissionAmount percent={data.order.card_commission || 0} amount={0} />
									)}>
										<div>
											<Input label='Nombre de tarjetahabiente' value={cardForm.card_name} onChange={onCardFormChange('card_name')} placeholder='Nombre del propietario de la tarjeta' readonly={sending} />
											<Input label='Número de tarjeta' value={cardForm.card_number} onChange={onCardFormChange('card_number')} placeholder='Dígitos de la tarjeta' maxLength={16+3} valueFormat={formatCreditCard} inputStyle={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }} readonly={sending} />
											<Field amount={2}>
												<Input label='Fecha de expiración' value={cardForm.card_expiration} onChange={onCardFormChange('card_expiration')} placeholder='MM/YY' valueFormat={formatExpiration} maxLength={5} readonly={sending} />
												<Input label='Código de seguridad' value={cardForm.card_code} onChange={onCardFormChange('card_code')} placeholder='CVV' maxLength={5} readonly={sending} />
											</Field>
											<Input label='Código postal' value={cardForm.postal_code} onChange={onCardFormChange('postal_code')} readonly={sending} />
											<Message list={cardPrompts} type='error' style={{ marginBottom: 0, marginTop: 10 }} />
										</div>
									</Accordion.Item>
								) : (
									<Accordion.Item key={`pm-ck-${a.payment_method}`} value={a.payment_method} title={a.method_name} rightTitle={<CommissionAmount percent={a.commission_percent} amount={a.commission_amount} />} />
								)
							))}
						</Accordion>
					</Groupper>
					{!!data.order.needs_location && (
						<Groupper title='Dirección' style={{ marginTop: 10 }}>
							<Input label='Calle' value={location.street} onChange={onLocationFormChange('street')} />
							<Field amount={2}>
								<Input label='Número exterior' value={location.exterior_number} onChange={onLocationFormChange('exterior_number')} />
								<Input label='Número interior' value={location.interior_number} onChange={onLocationFormChange('interior_number')} />
							</Field>
							<Field amount={2}>
								<Input label='Colonia' value={location.neighborhood} onChange={onLocationFormChange('neighborhood')} />
								<Input label='Ciudad' value={location.city} onChange={onLocationFormChange('city')} />
							</Field>
							<Field amount={2}>
								<Input label='Estado' value={location.state} onChange={onLocationFormChange('state')} />
								<Input label='Código Postal' value={location.zipcode} onChange={onLocationFormChange('zipcode')} />
							</Field>
							<Message list={locationPrompts} type='error' />
						</Groupper>
					)}
					<div style={{ textAlign: 'center', color: 'gray', fontWeight: 'bold', marginTop: 5 }}>
						Compra {data.order.order_id ? `@${data.order.order_id}` : data.order.order_hash}
					</div>
				</div>
				<div className='column small'>
					<Groupper title='Compra' fitted actions={(
						<Button fluid color='black' text='Completar compra' size='big' onClick={submitPayment} onLoadingChanged={setSending} />
					)}>
						<table className="fr striped table">
							<tbody>
								<tr>
									<td>Boletos</td>
									<td className="collapsing">${addCommas(tickets_total)}</td>
								</tr>
								<tr>
									<td>Cargo por servicio</td>
									<td className="collapsing">${addCommas(commission_total)}</td>
								</tr>
								<tr>
									<td>Forma de pago</td>
									<td className="collapsing">
										{formData.payment_method ? (
											`$${addCommas(payment_cost)}`
										) : (
											<Icon color='grey' name='minus' />
										)}
									</td>
								</tr>
								<tr>
									<td>Entrega</td>
									<td className="collapsing">${addCommas(data.order.delivery_cost)}</td>
								</tr>
								<tr className="totals">
									<td>Total</td>
									<td className="collapsing">${addCommas(tickets_total+commission_total+payment_cost+data.order.delivery_cost)}</td>
								</tr>
								{checkoutPrompts && checkoutPrompts.length>0 ? (
									<tr className='totals'>
										<td colSpan={2}>
											<Message type='error' list={[checkoutPrompts]} style={{ marginBottom: 0, textAlign: 'left' }} />
										</td>
									</tr>
								) : null}
							</tbody>
						</table>
					</Groupper>
					<Groupper className='tickets' title='Boletos' fitted>
						<div className="fr items small tickets divided">
							{data.tickets.map(a=>(
								<div className="item" key={`tkt-${a.ticket_hash}`}>
									<Image src={`${CDN_URL}/events/${a.event_id}/800.webp`} alt={a.event_name} fallback={PLACEHOLDER_IMG} />
									<div className="info">
										<div className="event">{a.event_name}</div>
										<div className="date">{moment.unix(a.ticket_date || a.date).format('DD/MMM/YY HH:mm')}</div>
										<div className="section meta">
											{a.section_name} - {a.price_name}
										</div>
										{a.numbered ? (
											<div className="seat meta">
												{a.seat_section ? (`${a.seat_section} - `) : 'Butaca'} {formatSeatNumber(a.seat_row, a.seat_number)}
											</div>
										) : null}
										{a.paid ? (
											<div className="paid">Pagado</div>
										) : (
											<div className="cost">${addCommas(a.ticket_cost+a.commission)}</div>
										)}
									</div>
								</div>
							))}
						</div>
					</Groupper>
				</div>
			</div>
		)}
	</div>
}

export default Payment;