import React, { useEffect, useState } from 'react';
import { Countdown, SeatmapSelect, SitePolicy } from '@arema/components';
import { SeatmapSeat, SectionSeatmap, SectionStatus, SetLoading } from '@arema/components/Classes';
import { addCommas, bindClose, formatSeatNumber, getDateStatusLocale } from '@arema/components/Util';
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';
import { CDN_URL, PLACEHOLDER_IMG, RECAPTCHA, CAPTCHA_ACTIVE } from '../CheckoutConfig';
import { DateData } from '../CheckoutClasses'
import { Icon } from 'semantic-ui-react';
import { firstBy } from 'thenby';
import { Image, Button, Groupper, Header, Message } from 'react-frontier';
import { useTitle } from '@arema/components/Hooks';
import { AccessCodeForm } from '../components';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';
import Validator from '@arema/components/Validator';
import API from '../CheckoutAPI';

import '../styles/checkout_seats.scss';

interface CartItem{
	section_id: number,
	section_name: string,
	price_id: number,
	price_name: string,
	seat_id?: number,
	quantity: number,
	cost: number
}

var Checkout = ()=>{
	var params = useParams();
	var [query, setSearchParams] = useSearchParams();
	var navigate = useNavigate();
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null)
	var [data, setData] = useState<DateData>(null);
	var [cart, setCart] = useState<CartItem[]>(null);
	var [policyModal, setPolicyModal] = useState<boolean>(false);
	var [acceptPolicy, setAcceptPolicy] = useState<boolean>(false);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [selectedSection, setSelectedSection] = useState<number>(null);
	var [selectedPrice, setSelectedPrice] = useState<number>(null);
	var [selectedDelivery, setSelectedDelivery] = useState<number>(null);
	var [captcha, setCaptcha] = useState<string>(CAPTCHA_ACTIVE ? null : 'CAPTCHA');
	var [seatmapError, setSeatmapError] = useState<string>(null);
	var [seatmap, setSeatmap] = useState<SectionSeatmap>(null);
	var [tokenExpired, setTokenExpired] = useState<boolean>(null);

	var getDateData = (access_token: string=null)=>{
		API.getDate(parseInt(params.date), query.get('qt'), (access_token || query.get('ac'))).then(res=>{
			if(res.error) return setLoadError(res.message);
			if(res.data.redirect){
				return window.location.href = res.data.redirect;
			}
			setData(res.data);
			if(res.data.event && res.data.event.event_name){
				setTitle(res.data.event.event_name);
			}
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando la información del evento. (LCL-1)');
		});
	}

	useEffect(()=>{
		if(!data && params.date && !Number.isNaN(parseInt(params.date))){
			getDateData();
		}else{
			if(!data) setTitle('Checkout');
		}
	}, [data, params, query]);

	if(!params.date || Number.isNaN(parseInt(params.date))) return <Header size='huge' text='404' subtext='No se encontró la página' />
	if(loadError) return <Header iconName='face-frown-open' text='Error' subtext={loadError} />
	if(!data){
		return <Header loading text='Cargando evento...' />
	}
	
	var resetSelection = ()=>{
		setCart(null);
		setSelectedDelivery(null);
		setSelectedPrice(null);
		setSelectedSection(null);
		setAcceptPolicy(false);
		setErrorPrompts(null);
	}

	var loadSeatmap = (section_id: number)=>{
		var section = data.sections.find(a=>a.section_id==section_id);
		setCart(null);
		if(!section || !section.numbered) return resetSelection();
		if(seatmap && seatmap.section_id==section_id) return;
		setSeatmapError(null);
		API.getSeatmap(data.date.date_id, section.section_id, query.get('qt')).then(res=>{
			if(res.error) return setSeatmapError(res.message);
			setSeatmap({
				section_id: section.section_id,
				stage_width: section.stage_width,
				stage_height: section.stage_height,
				stage_x: section.stage_x,
				stage_y: section.stage_y,
				seats_height: section.seats_height,
				seats_width: section.seats_width,
				seats: res.data.map((a: any[])=>({
					seat_id: a[0],
					seat_x: a[1],
					seat_y: a[2],
					seat_section: a[3],
					seat_row: a[4],
					seat_number: a[5],
					seat_status: a[6],
				})),
			})
		}).catch(err=>{
			return setSeatmapError('Hubo un error inesperado cargando el mapa de butacas. (LCL-1)');
		})
	}

	var showSection = (section_id: number)=>{
		return ()=>{
			setAcceptPolicy(false);
			setCart(null);
			setSelectedPrice(null);
			setSelectedDelivery(null);

			var section = data.sections.find(a=>a.section_id==section_id);
			if(!section) return;
			if(section.numbered){
				if(section.prices.length==1){
					setSelectedPrice(section.prices[0].price_id);
					loadSeatmap(section_id);
				}
			}
			setSelectedSection(section_id);
		}
	}

	var addTicket = (section_id: number, price_id: number, remove: boolean=false)=>{
		return ()=>{
			var ct = cart ? [...cart] : [];
			var cx = ct.findIndex(a=>a.price_id==price_id && a.section_id==section_id);
			var section = data.sections.find(a=>a.section_id==section_id);
			if(!section) return;
			var price = section.prices.find(a=>a.price_id==price_id);
			if(!price) return;
			
			var max_tickets = price.max_tickets%price.multiple!==0 ? price.max_tickets-(price.max_tickets%price.multiple) : price.max_tickets;
			if(cx==-1 && !remove){
				ct.push({
					section_id: section.section_id,
					section_name: section.section_name,
					price_id: price.price_id,
					price_name: price.price_name,
					quantity: 0,
					cost: price.cost+price.commission,
				});
				cx = ct.length-1;
			}
			
			ct[cx].quantity = Math.min(max_tickets, Math.max(0, ct[cx].quantity+((remove ? -1 : 1)*price.multiple)));
			setCart(ct);
		}
	}

	var submit = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator({
			cart,
			delivery: selectedDelivery,
			accept: acceptPolicy,
			captcha
		}, {
			cart: [{
				rule: (val: any)=>{
					if(!val || val.length==0) return false;
					var cart_items = (val as any[]).reduce((a,b)=>a+Math.max(0, b.quantity), 0);
					return cart_items>0;
				},
				prompt: 'Favor de seleccionar tus boletos',
			}],
			delivery: [
				{ rule: 'number', prompt: 'Favor de seleccionar la forma de entrega' },
			],
			accept: [
				{ rule: 'empty', prompt: 'Favor de aceptar las políticas de AREMA Ticket.' },
			],
			captcha: [
				{ rule: 'empty', prompt: 'Favor de realizar el captcha.' },
			]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createOrder(cart.map(a=>({
			seat_id: a.seat_id || null,
			price_id: a.price_id,
			quantity: a.quantity,
		})), selectedDelivery, captcha, query.get('qt')).then(res=>{
			if(res.error){
				var prompts = [res.message];
				if(seatmap && seatmap.seats && res.data && res.data.unavailable_seats && res.data.unavailable_seats.length>0){
					var seats = [];
					for(var i of res.data.unavailable_seats){
						var st = seatmap.seats.find(a=>a.seat_id==i);
						if(!st) continue;
						seats.push(formatSeatNumber(st.seat_row, st.seat_number));
					}
					if(seats.length==res.data.unavailable_seats.length){
						prompts.push(`Butacas no disponibles: ${seats.join(', ')}`)
					}
				}
				return setErrorPrompts(prompts);
			}
			if(!res.data || !res.data.token){
				return setErrorPrompts(['Hubo un error inesperado creando el pedido (LCL-NT-1)']);
			}
			navigate(`/c/${res.data.token}`);
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error inesperado creando el pedido (LCL-1)'])
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onSeatsSelected = (seats: SeatmapSeat[])=>{
		if(!selectedSection || !selectedPrice) return;
		var section = data.sections.find(a=>a.section_id==selectedSection);
		if(!section) return;
		var price = section.prices.find(a=>a.price_id==selectedPrice);
		if(!price) return;
		if(seats.length>price.max_tickets) return;

		var new_cart = seats.map(a=>({
			section_id: section.section_id,
			section_name: section.section_name,
			price_id: price.price_id,
			price_name: price.price_name,
			seat_id: a.seat_id,
			quantity: 1,
			cost: price.cost+price.commission,
		}))
		setCart(new_cart);
		return true;
	}

	var restartQueue = ()=>{
		query.delete('qt');
		setSearchParams(query);
		setData(null);
	}

	var onTokenExpired = ()=>{
		setTokenExpired(true);
	}

	var onAccessToken = (token: string)=>{
		if(!token) return;
		query.set('ac', token);
		setSearchParams(query);
		setData(null);
		// getDateData(token);
	}

	if(tokenExpired){
		return <Header 
			iconName='exclamation-circle' 
			text='Fuera de tiempo' 
			subtext='Se ha acabdo del tiempo para seleccionar los boletos.' 
			subheaderStyle={{ fontSize: 18 }} 
			actions={(
				<Button text='Empezar de nuevo' color='black' onClick={restartQueue} style={{ width: 200, padding: 5 }} />
			)}
		/>
	}

	var SelectSection =  !!data.sections && <>
		<div className="ar section label">Selecciona la sección de tus boletos</div>
		<div className="ar sections" style={{ minHeight: 400 }}>
			{data.sections.sort(firstBy('status', 'asc')).map(a=>(
				<div className={classNames('section', {
					soldout: a.status===SectionStatus.SOLD_OUT
				})} key={`sect-item-${a.section_id}`} onClick={a.status===SectionStatus.AVAILABLE ? showSection(a.section_id) : null}>
					<div className="price">
						{a.status===SectionStatus.SOLD_OUT ? (
							'Agotado'
						) : <>
							${addCommas(a.prices_min, true, true)}
							{a.prices_min!==a.prices_max ? (
								<> - ${addCommas(a.prices_max, true, true)}</>
							) : null}
						</>}
					</div>
					<div className="name">{a.section_name}</div>
				</div>
			))}
		</div>
	</>

	var cart_items = 0, tickets_total = 0, delivery_cost = 0;
	if(cart){
		for(var i of cart){
			cart_items += i.quantity || 0;
			tickets_total += (i.cost || 0)*(i.quantity || 0);
		}
	}
	if(selectedDelivery){
		var dl = data.delivery.find(a=>a.delivery_id==selectedDelivery);
		if(dl) delivery_cost = dl.delivery_cost;
	}

	var section = (selectedSection && data.sections) ? data.sections.find(a=>a.section_id===selectedSection) : null;
	var SectionPrices = (!section || section.numbered) ? null : <div style={{ minHeight: cart_items>0 ? 0 : 400 }}>
		<div className="ar section label">Area seleccionada</div>
		<div className="ar sections">
			<div className="section placeholder">
				<div className="name">{section.section_name}</div>
			</div>
		</div>
		<div className="ar section label" style={{ marginTop: 10 }}>Selecciona tus boletos</div>
		<div className="ar prices">
			{section.prices.map(a=>{
				var quantity = 0;
				if(cart){
					var c = cart.find(c=>c.price_id==a.price_id && c.section_id==section.section_id);
					if(c) quantity = c.quantity;
				}

				return <div className="price" key={`sect${section.section_id}-pr${a.price_id}`}>
					<div className="cost">${addCommas(a.cost+a.commission, true, true)}</div>
					<div className="name">{a.price_name}</div>
					<div className="quantity">
						<Button className='minus' color='black' iconName="minus" onClick={addTicket(section.section_id, a.price_id, true)} />
						<div className="amount">{quantity}</div>
						<Button className='plus' color='black' iconName="plus" onClick={addTicket(section.section_id, a.price_id)} />
					</div>
				</div>
			})}
		</div>
	</div>

	var SectionNumbered = !section || !section.numbered ? null : (
		selectedPrice ? (
			<SeatmapSelect seatmap={seatmap} error={seatmapError} onSelected={onSeatsSelected} loading={!seatmap} selected={cart ? cart.filter(a=>!!a.seat_id).map(a=>a.seat_id) : []} />
		) : (
			<div>
				<div style={{ minHeight: 350 }}>
					<div className="ar section label">Area seleccionada</div>
					<div className="ar sections">
						<div className="section placeholder">
							<div className="name">{section.section_name}</div>
						</div>
					</div>
					<div className="ar section label" style={{ marginTop: 10 }}>Selecciona el precio de tus boletos</div>
					<div className="ar selectable prices">
						{section.prices.length==0 ? (
							<Message text='No hay precios disponibles para esta sección' header='Atención' centered type='error' />
						) : section.prices.map(a=>(
							<div className="price" key={`sect${section.section_id}-pr${a.price_id}`} onClick={()=>{
								setSelectedPrice(a.price_id);
								loadSeatmap(selectedSection);
							}}>
								<div className="cost">
									${addCommas(a.cost+a.commission, true, true)}
								</div>
								<div className="name">{a.price_name}</div>
							</div>
						))}
					</div>
				</div>
				{section.prices.length>0 ? (
					<Message centered type='info' text='En seguida podrás seleccionar tus butacas' />
				) : null}
			</div>
		)
	)

	return <div className="ar checkout-contents">
		<div className="ar event">
			<Image src={`${CDN_URL}/events/${data.event.event_id}/200.webp`} fallback={PLACEHOLDER_IMG} alt={data.event.event_name} />
			<div className="info">
				<div className="name">{data.event.event_name}</div>
				<div className="venue">{data.date.venue_name}</div>
				<div className="city">{data.date.city}</div>
			</div>
		</div>
		{!!data && !!data.expiration && (
			<Message centered className='queue' type='info'>
				<div className="content">
					<Header text='¡Es tu turno!' size='small' centered={false} />
					<Countdown unix={data.expiration} className='countdown' onFinish={onTokenExpired} />
					Selecciona tus boletos ya que tienes tiempo para completar tu orden. Si se expira el tiempo, tendrás que regresar a la fila.
					<div style={{ fontSize: 12, lineHeight: '12px', marginTop: 5 }}>Turno: <b>{data.order_id.toString(18).toUpperCase()}</b></div>
				</div>
			</Message>
		)}
		{data.access_code ? (
			<AccessCodeForm eventId={data.event.event_id} onToken={onAccessToken} />
		) : (
			<Groupper className={classNames('selection', {
				numbered: section && selectedPrice && section.numbered,
				fitted: section && selectedPrice && section.numbered,
			})} style={{ maxWidth: (section?.numbered && selectedPrice) ? 1000 : 500 }} title='Checkout' titleCentered>
				{!data.date.available ? (
					<div className='unavailable'>
						<Header text={getDateStatusLocale(data.date.status_id)} subtext='Esta fecha no está disponible para venta web' iconName='face-frown-open' />
					</div>
				) : <>
					{!selectedSection ? (
						SelectSection
					) : section ? (
						!section.numbered ? SectionPrices : SectionNumbered
					) : (
						<Header size='small' text='Error' subtext='No se encontró la sección seleccionada' />
					)}
					{cart_items>0 ? (
						<div style={{ marginTop: section && section.numbered ? 0 : 30 }}>
							<div className="ar section label">Selecciona la forma de entrega</div>
							{data.delivery.length>0 ? (
								<div className="ar delivery">
									{data.delivery.map(a=>(
										<div className={classNames('method', {
											active: selectedDelivery==a.delivery_id
										})} key={`dlv-${a.delivery_id}`} onClick={()=>setSelectedDelivery(a.delivery_id)}>
											<Icon name='check circle' />
											<div className="name">
												{a.needs_location ? (
													<Icon name="truck" style={{ marginRight: 10 }} />
												) : null}
												{a.method_name}
											</div>
											<div className="cost">{a.delivery_cost>0 ? `$${addCommas(a.delivery_cost, true, true)}` : 'Gratis'}</div>
										</div>
									))}
								</div>	
							) : (
								<Header text='Error' subtext={`Este evento no tiene formas de envío configuradas (DTND-${data.date.date_id})`} />
							)}
						</div>
					) : null}
					{!!selectedSection && cart_items>0 && !!selectedDelivery && (
						<div className={classNames('ar terms checkbox', {
							active: acceptPolicy
						})} style={{ marginTop: 10 }} onClick={()=>setAcceptPolicy(!acceptPolicy)}>
							<i className="icon"></i>
							<div className="label">
								<div className="text">Acepto las políticas de AREMA Ticket</div>
							</div>
						</div>
					)}

					{selectedDelivery && cart_items>0 && CAPTCHA_ACTIVE ? (
						<div className="ar captcha">
							<ReCAPTCHA 
								hl='es-419'
								sitekey={RECAPTCHA}
								onChange={setCaptcha}
								onExpired={()=>setCaptcha(null)}
							/>
						</div>
					) : null}
					{cart_items>0 ? (
						<table className="totals">
							<tbody>
								{section && section.numbered ? (
									<tr>
										<td>Butacas</td>
										<td>{addCommas(cart.length, false)}</td>
									</tr>
								) : null}
								<tr>
									<td>Boletos</td>
									<td className="tickets">${addCommas(tickets_total)}</td>
								</tr>
								<tr>
									<td>Entrega</td>
									<td className="delivery">
										{selectedDelivery ? (
											'$'+addCommas(delivery_cost)
										) : (
											<Icon name='minus' />
										)}
									</td>
								</tr>
								<tr className="subtotal">
									<td>Subtotal</td>
									<td className="total">${addCommas(tickets_total+delivery_cost)}</td>
								</tr>
							</tbody>
						</table>
					) : null}
					<Message list={errorPrompts} type='error' style={{ marginTop: 10 }} />
					{selectedSection ? <>
						<div className="actions" style={{ display: 'flex', justifyContent: 'center' }}>
							<Button size='big' basic text='Regresar' onClick={resetSelection} style={{ marginRight: 10, minWidth: 150, fontSize: 14 }} />
							<Button size='big' text='Continuar' color='black' disabled={cart_items<=0 || !selectedDelivery} onClick={submit} />
						</div>
					</> : null}
				</>}
			</Groupper>
		)}
		<div style={{ justifyContent: 'center', display: 'flex', width: '100%', marginTop: 15, marginBottom: 15 }}>
			<Button basic text='Ver políticas' onClick={bindClose(setPolicyModal, true)} />
			<Button basic text='Aviso de privacidad' as={Link} to={'/privacidad'} target='_blank' />
		</div>
		<SitePolicy open={policyModal} onClose={bindClose(setPolicyModal)} />
	</div>
}

export default Checkout;